import React from "react";
import Navbar from "../../Components/Navbar/Navbar";

function AboutUS() {
  return (
    <>
      <Navbar show={false} />
      <section id="about" className="about" data-aos="fade-up">
        <div className="container">
          <div className="section-title">
            <h2>About</h2>
            <p>About Us</p>
          </div>

          <div className="row content">
            <div className="col-lg-6">
              <p>
                At Loan Gen, we are dedicated to transforming the way South
                Africans find and compare loans. By leveraging cutting-edge
                technology, we aim to simplify the lending process, connecting
                individuals with the best financial solutions tailored to their
                unique needs and circumstances.
              </p>
              <ul>
                <li>
                  <i className="ri-check-double-line"></i> We streamline the
                  process of applying for finance, making it simple and
                  straightforward for our users. Every month, we help thousands
                  of individuals navigate their loan options to make informed
                  decisions that align with their financial goals.
                </li>
                <li>
                  <i className="ri-check-double-line"></i> Our platform is
                  designed to ensure compliance with South African lending
                  regulations while offering a seamless, user-friendly
                  experience for borrowers and lenders alike.
                </li>
                <li>
                  <i className="ri-check-double-line"></i> By connecting users
                  with personalized loan offers, we empower them to make choices
                  that foster financial stability and growth.
                </li>
              </ul>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <p>
                Loan Gen collaborates with a panel of trusted lenders to provide
                users with tailored loan options in real-time. Our advanced
                comparison tool offers instant access to personalized offers,
                ensuring that users find the financial solutions that suit their
                needs quickly and efficiently.
              </p>
              <p>
                As a trusted partner, we help lenders increase their brand
                visibility and generate high-quality leads, all while offering
                comprehensive insights and analytics to optimize their customer
                acquisition strategies. With a focus on innovation and
                transparency, we are committed to reshaping the financial
                services landscape in South Africa.
              </p>
              <p>
                Whether you’re a first-time borrower or seeking options for debt
                consolidation, Loan Gen is here to guide you through every step
                of the journey. From submitting your application to exploring
                loan offers, we work tirelessly to ensure a smooth and
                stress-free process.
              </p>
            </div>
          </div>
          <div className="row content mt-4 pt-5">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>About Loan Gen</h2>
                <p>Get to Know Us</p>
              </div>
              <p>
                Loan Gen was created to serve South African consumers, making it
                easier for individuals to find and compare the best loan options
                available online. Whether you're fulfilling a dream or
                overcoming financial challenges, we aim to connect you with
                trusted lenders in a secure, reliable, and efficient manner.
              </p>
              <p>
                Our partners are widely known and licensed South African lenders
                who adhere to strict financial regulations. Together, we ensure
                that your borrowing experience is safe, transparent, and
                stress-free.
              </p>
              <p>
                Loan Gen is committed to helping you achieve your financial
                goals. With our innovative tools and expert support, we bridge
                the gap between borrowers and lenders, creating opportunities
                for financial growth and stability.
              </p>
            </div>
          </div>

          <div className="row content mt-4 pt-2">
            <div className="col col-12 col-md-6">
              <div className="content mt-4 pt-5">
                <div className="col-lg-12">
                  <div className="section-title">
                    <h2>Safe</h2>
                    <p>Your Security Matters</p>
                  </div>
                  <p>
                    Security is at the heart of everything we do. Our mission is
                    to connect you with the best available loan offers in a safe
                    and trusted environment. With the rise of online lending,
                    finding reputable and secure options can be challenging. At
                    Loan Gen, we prioritize your safety at every step, ensuring
                    your personal data and financial information are always
                    protected.
                  </p>
                </div>
              </div>
            </div>
            <div className="col col-12 col-md-6">
              <div className="row content mt-4 pt-5">
                <div className="col-lg-12">
                  <div className="section-title">
                    <h2>Free</h2>
                    <p>No Hidden Costs</p>
                  </div>
                  <p>
                    Loan Gen is completely free for borrowers. There are no
                    hidden fees or charges when you use our platform. Instead,
                    we partner with trusted lenders who pay us a commission,
                    allowing us to deliver exceptional value to you at no cost.
                    Our mission is to make finding the right loan simple,
                    affordable, and hassle-free.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row content mt-4 pt-5">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>Fast</h2>
                <p>Quick and Convenient</p>
              </div>
              <p>
                Time is precious, and at Loan Gen, we ensure you get the
                financial assistance you need as quickly as possible. With just
                one application, you can reach multiple lenders and receive
                tailored offers in real time. Our fully digital service means
                faster responses, less paperwork, and a seamless experience from
                start to finish.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutUS;
