import React from "react";
import Navbar from "../../../Components/Navbar/Navbar";
import Form from "./Form";
function FormsURL() {
  return (
    <div className="pb-5">
      <Navbar show={false} />
      <Form />
    </div>
  );
}

export default FormsURL;
