import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "./Homepage.css";
import NavBar from "../../Components/Navbar/Navbar.js";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Form from "../Loan/Forms/Form.js";
function Homepage() {
  const [content, setContent] = useState(<Form FormType="Personal Loans" />);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
  });

  const loanFormRef = useRef(null);
  const renderContent = () => {
    return content;
  };

  const handleLoanSelection = (type) => {
    setContent(<Form FormType={type} />);
  };
  if (loanFormRef.current) {
    loanFormRef.current.scrollIntoView({ behavior: "smooth" });
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted: ", formData);
    setShowModal(false); // Close the modal after submission
  };

  return (
    <>
      <NavBar onLoanSelect={handleLoanSelection} />
      <section id="hero" className="hero">
        <div className="hero-overlay Overlay"></div>
        <div className="hero-container d-flex justify-content-center text-center Container mt-5 pt-5">
          <div className="container">
            <h1 className="pt-0 mt-0 mt-md-5 pt-md-5 animate__animated animate__fadeInDown text-white Heading_text">
              Need Help
            </h1>
            <h1 className="animate__animated animate__fadeInDown text-white Heading_text2">
              Finding a Loan ?
            </h1>
            <a
              href="#main"
              className="btn-get-started animate__animated animate__fadeInUp scrollto text-decoration-none pt-3"
            >
              Enquire Now
            </a>
          </div>
        </div>
      </section>

      <main id="main">
        <section id="LoanForm" className="about" ref={loanFormRef}>
          <div className="container" data-aos="fade-up">
            <div className="row content">{renderContent()}</div>
          </div>
        </section>

        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>About</h2>
              <p>About Us</p>
            </div>

            <div className="row content">
              <div className="col-lg-6">
                <p>
                  We dedicated to helping individuals across South Africa find
                  affordable loans that match their unique circumstances.
                </p>
                <ul>
                  <li>
                    <i className="ri-check-double-line"></i> We streamline the
                    process of applying for finance, making it as simple and
                    straightforward as possible. Every month, we assist
                    thousands of people in narrowing down their options to find
                    the best loan suited to their needs.
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i> Trust LoanGen to
                    help you find the right financial solution, tailored to your
                    needs, while ensuring compliance with South African lending
                    regulations.
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0">
                <p>
                  Working with our panel of lenders, we take pride in going the
                  extra mile to secure the funds you need as quickly as
                  possible. Once you submit a fully completed application, we
                  will forward it to our lenders and email you the preliminary
                  decision along with any available options.
                </p>
                <a
                  href="/about-us"
                  className="btn-learn-more text-decoration-none"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="why-us" className="why-us section-bg">
          <div className="container-fluid" data-aos="fade-up">
            <div className="row">
              <div
                className="col-lg-5 align-items-stretch video-box whyUs"
                data-aos="zoom-in"
                data-aos-delay="100"
              ></div>

              <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch">
                <div className="content">
                  <h3>
                    {" "}
                    <strong>At Loan Gen,</strong>
                  </h3>
                </div>
                <div className="content">
                  <p>
                    we’re dedicated to helping you secure the perfect loan
                    tailored to your needs and budget. As a leading credit
                    broker, we partner with a diverse range of lenders to offer
                    you an array of loan choices, including personal, unsecured,
                    and homeowner loans—even if your credit isn’t perfect*.
                  </p>
                  <p>
                    Our intuitive online application process is quick and easy,
                    providing you with fast decisions and the best loan options
                    available.
                  </p>
                  <p>
                    <a> *Terms and conditions apply.</a>
                  </p>
                </div>

                <div className="accordion-list">
                  <ul>
                    <li>
                      <a
                        data-bs-toggle="collapse"
                        className="collapse"
                        data-bs-target="#accordion-list-1"
                      >
                        <span>01</span> What are the different types of loans
                        available?{" "}
                        <i className="bx bx-chevron-down icon-show"></i>
                        <i className="bx bx-chevron-up icon-close"></i>
                      </a>
                      <div
                        id="accordion-list-1"
                        className="collapse show"
                        data-bs-parent=".accordion-list"
                      >
                        <p>
                          At Loan Gen, we offer a variety of loan options to
                          suit your needs. Here’s a breakdown of the types of
                          loans you can consider:
                        </p>
                        <ul>
                          <li>
                            <strong>Personal Loans:</strong> Perfect for any
                            unexpected expenses or personal needs. These loans
                            offer flexibility in terms of amounts and repayment
                            periods. With Loan Gen, you can get a quick decision
                            and competitive rates, even if your credit score
                            isn’t perfect.
                          </li>
                          <li>
                            <strong>Unsecured Loans:</strong> These loans don’t
                            require collateral, making them an ideal choice if
                            you don’t have assets to pledge. Enjoy
                            straightforward terms and quick approvals with our
                            trusted lenders.
                          </li>
                          <li>
                            <strong>Homeowner Loans:</strong> If you’re a
                            homeowner, you can leverage the value of your
                            property to secure a loan with favorable terms.
                            Ideal for large expenses or debt consolidation,
                            these loans often come with lower interest rates.
                          </li>
                          <li>
                            <strong>Bad Credit Loans:</strong> Don’t let a
                            less-than-perfect credit history hold you back. Our
                            lenders are experienced in offering loans to those
                            with bad credit, providing you with the financial
                            support you need when you need it.
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <a
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-list-2"
                        className="collapsed"
                      >
                        <span>02</span> How can I benefit from taking a loan?{" "}
                        <i className="bx bx-chevron-down icon-show"></i>
                        <i className="bx bx-chevron-up icon-close"></i>
                      </a>
                      <div
                        id="accordion-list-2"
                        className="collapse"
                        data-bs-parent=".accordion-list"
                      >
                        <p>
                          Taking a loan can provide immediate financial relief
                          and open up new opportunities. Here’s how:
                        </p>
                        <ul>
                          <li>
                            <strong>Immediate Financial Relief:</strong> Access
                            funds quickly to cover urgent expenses or
                            emergencies without having to dip into savings.
                          </li>
                          <li>
                            <strong>Debt Consolidation:</strong> Combine
                            multiple debts into a single loan with a lower
                            interest rate, simplifying your finances and
                            potentially saving money.
                          </li>
                          <li>
                            <strong>Home Improvements:</strong> Finance
                            renovations or repairs to increase the value of your
                            property and enhance your living space.
                          </li>
                          <li>
                            <strong>Personal Growth:</strong> Invest in
                            education, start a new venture, or achieve personal
                            goals with the financial support of a loan.
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <a
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-list-3"
                        className="collapsed"
                      >
                        <span>03</span> What should I consider before applying
                        for a loan?{" "}
                        <i className="bx bx-chevron-down icon-show"></i>
                        <i className="bx bx-chevron-up icon-close"></i>
                      </a>
                      <div
                        id="accordion-list-3"
                        className="collapse"
                        data-bs-parent=".accordion-list"
                      >
                        <p>
                          Before applying for a loan, consider the following
                          factors to ensure it’s the right choice for you:
                        </p>
                        <ul>
                          <li>
                            <strong>Interest Rates:</strong> Compare rates from
                            different lenders to find the best deal. Lower
                            interest rates can save you money over the life of
                            the loan.
                          </li>
                          <li>
                            <strong>Repayment Terms:</strong> Ensure that the
                            loan terms fit your budget and financial situation.
                            Flexible repayment options can help you manage your
                            payments more effectively.
                          </li>
                          <li>
                            <strong>Fees and Charges:</strong> Be aware of any
                            additional fees or charges associated with the loan.
                            Understanding these can help you avoid surprises and
                            manage costs.
                          </li>
                          <li>
                            <strong>Eligibility Criteria:</strong> Check the
                            lender’s eligibility requirements to make sure you
                            meet them before applying. This can increase your
                            chances of approval.
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="services">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Services</h2>
              <p>Check our Services</p>
            </div>

            <div className="row">
              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bxl-dribbble"></i>
                  </div>
                  <h4>
                    <a href="" className="text-decoration-none">
                      No Hidden Costs
                    </a>
                  </h4>
                  <p>
                    Our service is completely free so you don’t have to worry
                    about fees.
                  </p>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bx-file"></i>
                  </div>
                  <h4>
                    <a href="" className="text-decoration-none">
                      Bad Credit Loans
                    </a>
                  </h4>
                  <p>
                    We can even help those with bad credit, CCJs, defaults and
                    late payments accepted.
                  </p>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bx-tachometer"></i>
                  </div>
                  <h4>
                    <a href="" className="text-decoration-none">
                      Great Customer Service
                    </a>
                  </h4>
                  <p>
                    Our customer service is rated Gold Trusted Service by Feefo
                    and our experienced consultants are on hand to offer support
                    five days a week.
                  </p>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bx-world"></i>
                  </div>
                  <h4>
                    <a href="" className="text-decoration-none">
                      Self-Employed Loans
                    </a>
                  </h4>
                  <p>
                    We can also source loan options for self-employed customers.
                  </p>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bx-slideshow"></i>
                  </div>
                  <h4>
                    <a href="" className="text-decoration-none">
                      Options Available
                    </a>
                  </h4>
                  <p>
                    We work with a panel of lenders to source loan options that
                    meet your requirements.
                  </p>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bx-arch"></i>
                  </div>
                  <h4>
                    <a href="" className="text-decoration-none">
                      Fast Online Enquiry
                    </a>
                  </h4>
                  <p>
                    With our simple online enquiry process, you can apply for a
                    loan in minutes
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="cta" className="cta">
          <div className="container" data-aos="zoom-in">
            <div className="text-center">
              <h3>Request a call back</h3>
              <p>
                Need assistance or have questions about your loan options? Fill
                out a quick form, and one of our representatives will give you a
                call to provide guidance, answer your queries, and help you find
                the best loan tailored to your needs.
              </p>
              <button
                className=" btn btn-primary"
                onClick={() => setShowModal(true)}
              >
                Request Call Back
              </button>
            </div>
          </div>
        </section>

        {showModal && (
          <div
            className="modal show d-block"
            tabIndex="-1"
            role="dialog"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Enter Your Details</h5>
                  <button
                    type="button"
                    className="close btn-close"
                    onClick={() => setShowModal(false)}
                    aria-label="Close"
                  ></button>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="modal-body">
                    <div className="form-group mb-3">
                      <label htmlFor="firstName">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="lastName">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="mobileNumber">Mobile Number</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="mobileNumber"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleInputChange}
                        required
                        pattern="[0-9]{10}" // 10-digit phone numbers
                        title="Enter a valid 10-digit mobile number"
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        <section id="faq" className="faq">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>F.A.Q</h2>
              <p>Frequently Asked Questions</p>
            </div>

            <div
              className="row faq-item d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="col-lg-5">
                <i className="bx bx-help-circle"></i>
                <h4>What types of loans do you offer?</h4>
              </div>
              <div className="col-lg-7">
                <p>
                  We offer a variety of loan options including personal loans,
                  payday loans, and consolidation loans. Our platform connects
                  you with reputable lenders who provide different types of
                  loans based on your needs and financial situation.
                </p>
              </div>
            </div>
            <div
              className="row faq-item d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="col-lg-5">
                <i className="bx bx-help-circle"></i>
                <h4>How can I apply for a loan through your website?</h4>
              </div>
              <div className="col-lg-7">
                <p>
                  To apply for a loan, simply fill out the online application
                  form with your personal details, employment information, and
                  loan requirements. Once submitted, our system will evaluate
                  your application and match you with suitable lenders. You will
                  receive loan offers based on your profile.
                </p>
              </div>
            </div>
            <div
              className="row faq-item d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="col-lg-5">
                <i className="bx bx-help-circle"></i>
                <h4>How long does it take to receive a loan?</h4>
              </div>
              <div className="col-lg-7">
                <p>
                  The processing time for a loan can vary depending on the
                  lender and the type of loan. Generally, you may receive a
                  response within a few hours to a few days. Once approved, the
                  funds are typically transferred to your bank account within a
                  few business days.
                </p>
              </div>
            </div>
            <div
              className="row faq-item d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className="col-lg-5">
                <i className="bx bx-help-circle"></i>
                <h4>Can I check my loan application status?</h4>
              </div>
              <div className="col-lg-7">
                <p>
                  Yes, you can check the status of your loan application by
                  logging into your account on our website. You will be able to
                  see updates and communicate with the lenders if necessary.
                </p>
              </div>
            </div>
            <div
              className="row faq-item d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="col-lg-5">
                <i className="bx bx-help-circle"></i>
                <h4>What should I do if I have trouble repaying my loan?</h4>
              </div>
              <div className="col-lg-7">
                <p>
                  If you encounter difficulties in repaying your loan, it’s
                  important to contact your lender immediately. They may offer
                  options such as repayment plans, loan extensions, or
                  restructuring. It’s crucial to communicate early to avoid
                  additional fees or penalties.
                </p>
              </div>
            </div>

            <div
              className="row faq-item d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="col-lg-5">
                <i className="bx bx-help-circle"></i>
                <h4>Is my personal information safe with you?</h4>
              </div>
              <div className="col-lg-7">
                <p>
                  We prioritize the security of your personal information. Our
                  website uses encryption and other security measures to protect
                  your data. We only share your information with lenders who are
                  part of our network and are committed to safeguarding your
                  privacy.
                </p>
              </div>
            </div>
            <div
              className="row faq-item d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="col-lg-5">
                <i className="bx bx-help-circle"></i>
                <h4>
                  Can I use the loan options provided by your website for any
                  purpose?
                </h4>
              </div>
              <div className="col-lg-7">
                <p>
                  Yes, the loan options available through our website can be
                  used for various purposes including debt consolidation, home
                  improvements, medical expenses, or other personal needs. Be
                  sure to discuss your intended use with the lender to ensure
                  the loan terms meet your requirements.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="contact" className="contact section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Contact</h2>
              <p>Contact Us</p>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="info-box">
                      <i className="bx bx-map"></i>
                      <h3>Our Durban</h3>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="info-box mt-4">
                      <i className="bx bx-envelope"></i>
                      <h3>Email Us</h3>
                      <p>
                        info@example.com
                        <br />
                        contact@example.com
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="info-box mt-4">
                      <i className="bx bx-phone-call"></i>
                      <h3>Call Us</h3>
                      <p>
                        +1 5589 55488 55
                        <br />
                        +1 6678 254445 41
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <form
                  action="forms/contact.php"
                  method="post"
                  role="form"
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="5"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>
                  <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit">Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Homepage;
