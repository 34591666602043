// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";

// Replace with your Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyCIHroliB6FtTpb1xQR-5sbUzYbDMOo_xA",
  authDomain: "loan-gen-database.firebaseapp.com",
  projectId: "loan-gen-database",
  storageBucket: "loan-gen-database.firebasestorage.app",
  messagingSenderId: "130019855796",
  appId: "1:130019855796:web:4c2563b0a1d98d1785b6e1",
  measurementId: "G-HYJ9DPRJJG",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // Initialize Firestore

export { db, addDoc, collection };
