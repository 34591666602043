import React, { useState, useEffect, useRef } from "react";
import img1 from "../../img/Logos/Finance27_Logo.png";
import img2 from "../../img/Logos/SouthernFinance_Logo.png";
import img3 from "../../img/Logos/BlinkFinance_Logo.png";
import img4 from "../../img/Logos/Mpowa_Logo.png";

const OfferPage = ({ formData }) => {
  const [formErrors, setFormErrors] = useState({});
  const firstDivRef = useRef(null);

  useEffect(() => {
    // Scroll to the first div when the component loads
    if (firstDivRef.current) {
      firstDivRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, []);
  return (
    <div>
      <section>
        <div class="container">
          <div class="mt-5">
            <div class="alert alert-primary text-center" role="alert">
              <div class="row mb-4">
                <div ref={firstDivRef} class="col-12 text-center">
                  <h3>
                    Congratulations!!! {formData.firstName}, {formData.lastName}
                  </h3>
                  <h4>Your Offers are below</h4>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-md-4 mb-4">
                  <div class="card border-primary pt-4 pb-4">
                    <div class="card-body text-center">
                      <h5 class="card-title">
                        Amount: R {formData.loanAmount}
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-4">
                  <div class="card border-primary pt-4 pb-4">
                    <div class="card-body text-center">
                      <h5 class="card-title">Term: 3 Months</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h3>Personal Loans</h3>
          </div>
          <div class="container mt-4">
            <div class="loan-banner">
              <div class="top-deal-ribbon">Hot Deal!</div>
              <div class="row align-items-center">
                <div class="col-12 col-md-4">
                  <img src={img1} alt="Loan Logo" class="img-fluid" />
                </div>
                <div class="col-4 col-md-2">
                  <div class="loan-amount">
                    Loan Amount:
                    <br /> R {formData.loanAmount}
                  </div>
                </div>
                <div class="col-4 col-md-2">
                  <div class="loan-term">
                    Term:
                    <br /> 3 Months
                  </div>
                </div>
                <div class="col-4 col-md-2">
                  <div class="loan-interest">
                    Interest Rate:
                    <br /> 7%
                  </div>
                </div>
                <div class="col-12 col-md-2">
                  <a
                    href="@Model.Url"
                    target="_blank"
                    class="btn btn-primary w-100"
                  >
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
            <div class="loan-banner">
              <div class="top-deal-ribbon">Hot Deal!</div>
              <div class="row align-items-center">
                <div class="col-12 col-md-4">
                  <div className="rounded pt-3 SouthernFinanceOffer">
                    <img src={img4} alt="Loan Logo" class="img-fluid" />
                  </div>
                </div>
                <div class="col-4 col-md-2">
                  <div class="loan-amount">
                    Loan Amount:
                    <br /> R {formData.loanAmount}
                  </div>
                </div>
                <div class="col-4 col-md-2">
                  <div class="loan-term">
                    Term:
                    <br /> 3 Months
                  </div>
                </div>
                <div class="col-4 col-md-2">
                  <div class="loan-interest">
                    Interest Rate: <br /> 7%
                  </div>
                </div>
                <div class="col-12 col-md-2">
                  <a
                    href="@Model.Url"
                    target="_blank"
                    class="btn btn-primary w-100"
                  >
                    Apply Now
                  </a>
                </div>
              </div>
            </div>

            <div class="loan-banner">
              <div class="row align-items-center">
                <div class="col-12 col-md-4">
                  <img src={img3} alt="Loan Logo" class="img-fluid" />
                </div>
                <div class="col-4 col-md-2">
                  <div class="loan-amount">
                    Loan Amount:
                    <br /> R {formData.loanAmount}
                  </div>
                </div>
                <div class="col-4 col-md-2">
                  <div class="loan-term">
                    Term:
                    <br /> 3 Months
                  </div>
                </div>
                <div class="col-4 col-md-2">
                  <div class="loan-interest">
                    Interest Rate: <br /> 7%
                  </div>
                </div>
                <div class="col-12 col-md-2">
                  <a
                    href="@Model.Url"
                    target="_blank"
                    class="btn btn-primary w-100"
                  >
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OfferPage;
