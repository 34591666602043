import React, { useState } from "react";
import PersonalInfo from "../FormSections/PersonalInfo/PersonalInfo.js";
import EmploymentDetails from "../FormSections/EmploymentDetails/EmploymentDetails.js";
import LoanDetails from "../FormSections/LoanDetails/LoanDetails.js";
import Review from "../FormSections/Review/Review.js";
import OfferPage from "../../Offers/OfferPage.js";
import Model from "../../../Models/Loan/LoanSubmit.json";
import { useLocation } from "react-router-dom";
import Navbar from "../../../Components/Navbar/Navbar.js";

function Form({ FormType }) {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState(Model);
  const nextStep = () => setStep((prevStep) => prevStep + 1);
  const previousStep = () => setStep((prevStep) => prevStep - 1);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const formTypeFromUrl = queryParams.get("FormType");

  // Use FormType prop or fallback to the value from URL
  const effectiveFormType = FormType || formTypeFromUrl || "Bad Credit Loans";

  const updateFormData = (stepData, step) => {
    setFormData((prevData) => ({
      ...prevData,
      [step]: stepData,
    }));
  };
  return (
    <>
      {/* <Navbar show={false} /> */}
      <div className="container pt-5">
        <div className="section-title">
          <h2>Enquire Now For Free</h2>
          <p>{effectiveFormType}</p>
        </div>
        {step === 1 && (
          <PersonalInfo
            formData={formData}
            setFormData={setFormData}
            nextStep={nextStep}
          />
        )}
        {step === 2 && (
          <EmploymentDetails
            formData={formData}
            setFormData={setFormData}
            nextStep={nextStep}
            previousStep={previousStep}
          />
        )}

        {step === 3 && (
          <LoanDetails
            formData={formData}
            setFormData={setFormData}
            nextStep={nextStep}
            previousStep={previousStep}
          />
        )}

        {step === 4 && (
          <Review
            formData={formData}
            setFormData={setFormData}
            nextStep={nextStep}
            previousStep={previousStep}
          />
        )}

        {step === 5 && <OfferPage formData={formData} />}
      </div>
    </>
  );
}

export default Form;
