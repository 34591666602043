import React, { useState, useEffect } from "react";
import classes from "./PersonalInfo.Module.css";
import Input from "../../../../Components/Input/Input.js";
import { addData } from "../../../../Services/services.js";

const PersonalInfo = ({ formData, setFormData, nextStep }) => {
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const errors = {};

    // Validate first name
    if (!formData.firstName || !/^[A-Za-z]+$/.test(formData.firstName)) {
      errors.firstName = "Please enter a valid first name with letters only.";
    }

    // Validate last name
    if (!formData.lastName || !/^[A-Za-z]+$/.test(formData.lastName)) {
      errors.lastName = "Please enter a valid last name with letters only.";
    }

    // Validate ID number (must be exactly 13 digits)
    if (!formData.idNumber || !/^\d{13}$/.test(formData.idNumber.trim())) {
      errors.idNumber =
        "Please enter a valid 13-digit South African ID number.";
    }

    // Validate phone number (must be exactly 10 digits)
    if (!formData.phone || !/^\d{10}$/.test(formData.phone.trim())) {
      errors.phone = "Please enter a valid 10-digit phone number.";
    }

    // Validate email address
    if (
      !formData.email ||
      !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)
    ) {
      errors.email = "Please enter a valid email address.";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleNext = async () => {
    if (validateForm()) {
      //handleAddItem();
      nextStep();
    }
  };

  // Function to add an item
  const handleAddItem = async () => {
    const addedItem = await addData("Application", formData);
    setFormData((prevData) => ({
      ...prevData,
      docID: addedItem.id,
    }));
  };

  useEffect(() => {
    // Initialize all tooltips on the page
    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
      new window.bootstrap.Tooltip(tooltipTriggerEl);
    });
  }, []);

  return (
    <div>
      <section
        id="step-1"
        className="card-section mt-5 p-4 bg-light shadow rounded"
        style={classes.step1}
      >
        <div className="row">
          <div className="col-9 col-md-10">
            <h2>Personal Information</h2>
          </div>
          <div className="col-3 col-md-2">1 / 4</div>
        </div>
        <form id="personal-info-form" className="pt-5 pt-md-0">
          <div className="row">
            <div className="col-md-6 mb-3">
              <Input
                LableName="First Name"
                Type="text"
                Id="first-name"
                Name="firstName"
                Placeholder="Please Enter Your Name"
                Value={formData.firstName}
                OnChange={handleChange}
                Tooltip="Please enter your first name here"
              />
              {formErrors.firstName && (
                <div className="text-danger">{formErrors.firstName}</div>
              )}
            </div>

            <div className="col-md-6 mb-3">
              <Input
                LableName="Last Name"
                Type="text"
                Id="last-name"
                Name="lastName"
                Placeholder="Please Enter Your Surname"
                Value={formData.lastName}
                OnChange={handleChange}
                Tooltip="Please enter your last name here"
              />
              {formErrors.lastName && (
                <div className="text-danger">{formErrors.lastName}</div>
              )}
            </div>

            <div className="col-md-6 mb-3">
              <Input
                LableName="ID Number"
                Type="text"
                Id="id-number"
                Name="idNumber"
                Placeholder="Please Enter Your ID Number"
                Value={formData.idNumber}
                OnChange={handleChange}
                Tooltip="Please enter your 13-digit ID number"
              />
              {formErrors.idNumber && (
                <div className="text-danger">{formErrors.idNumber}</div>
              )}
            </div>

            <div className="col-md-6 mb-3">
              <Input
                LableName="Phone Number"
                Type="text"
                Id="phone-number"
                Name="phone"
                Placeholder="Please Enter Your Phone Number"
                Value={formData.phone}
                OnChange={handleChange}
                Tooltip="Please enter your 10-digit phone number"
              />
              {formErrors.phone && (
                <div className="text-danger">{formErrors.phone}</div>
              )}
            </div>

            <div className="col-md-12 mb-3">
              <Input
                LableName="Email Address"
                Type="email"
                Id="email-address"
                Name="email"
                Placeholder="Please Enter Your Email"
                Value={formData.email}
                OnChange={handleChange}
                Tooltip="Please enter your email address"
              />
              {formErrors.email && (
                <div className="text-danger">{formErrors.email}</div>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-primary w-50"
              onClick={handleNext}
            >
              Next
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default PersonalInfo;
